.contactTitle,
.link {
  font-size: var(--px30);
  font-weight: 800;
  margin-bottom: var(--px12);
  transition: all 0.5s ease;
  display: flex;
}

h1 {
  font-size: calc(var(--index) * 2);
  font-weight: 800;
  margin: var(--px38) 0 var(--px30);
}

.decription {
  font-size: var(--px30);
}

.linkCatalog {
  background: #ac0a08;
  border: 1px solid #ac0a08;
  display: block;
  text-align: center;
  padding: var(--px10) var(--px16);
  width: calc(var(--index) * 6.6);
  font-size: var(--px30);
  margin: var(--px15) 0;
  min-width: 20%;
  border-radius: 3px;
}

.linkCatalog:hover {
  -webkit-box-shadow: 0px 0px 4px 4px rgba(172, 10, 8, 0.2);
  -moz-box-shadow: 0px 0px 4px 4px rgba(172, 10, 8, 0.2);
  box-shadow: 0px 0px 4px 4px rgba(172, 10, 8, 0.2);
  transition: all 0.5s ease;
}

.infoWrapper {
  display: flex;
  justify-content: space-between;
  margin: var(--px50) 5px;
}

.info {
  width: 38%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 4px;
}

.visual {
  width: 50%;
  position: relative;
  flex-shrink: 0;
}

.visualImg{
  width: 100%;
  border: 4px solid #AC0A08;
  margin-top: -4px;
  position: relative;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 70% 100%, 70% 80%, 30% 80%, 30% 100%, 0% 100%);
}
.visualImg img{
  height: 100%;
  width: auto;
  flex-shrink: 0;
}
.visualBlock{
  border: 4px solid #AC0A08;
  border-bottom: none;
  width: 40%;
  height: 20%;
  position: absolute;
  left: calc(30% + 1px);
  bottom: 2px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.brandsButton {
  background-color: #ac0a08;
  border: 1px solid #ac0a08;
  padding: var(--px15) var(--px26);
  color: #fff;
  font-size: var(--px26);
  border-radius: 3px;
  width: 100%;
}

.link {
  display: flex;
}

.infoDescriptionText {
  font-size: var(--px26);
  flex: 1; /* Даем текстовому блоку возможность занимать оставшееся пространство */
  max-width: 95%; /* Ограничиваем максимальную ширину текстового блока */
  transition: font-size 0.5s ease; /* Добавим анимацию для плавного изменения размера шрифта */
}

/* Медиа-запрос для адаптации шрифта при изменении размеров экрана */
@media screen and (max-width: 1920px) {
  .infoDescriptionText {
    font-size: var(--px21);
  }
}

.infoDescription {
  position: relative;
  padding: var(--px40);
  height: calc(40% - 80px);
  background:linear-gradient(-45deg, transparent 28px, #2e2e2e 0);
  display: flex; /* Используем flex для выравнивания элементов */
  flex-direction: column; /* Изменяем направление flex на column */
  
}
.infoDescription:before {
  transition: all 0.5s ease;
  content: "";
  position: absolute;
  background: linear-gradient(-45deg, transparent 30px, #AC0A08 0);
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  z-index: -2;
  top: -4px;
  left: -4px;
  opacity: 0;
}
.infoDescription:hover:before {
  opacity: 1;
}
.contactTitle2{
  transition: all 0.5s ease;
  margin-right: 7px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-left: 16px solid #FFFFFF;
  border-bottom: 10px solid transparent;
  position: relative;
  display: block;
}
.infoDescription:hover > .contactTitle > .contactTitle2{
  color: #AC0A08;
  border-left: 16px solid #AC0A08;
}

.brandsButton:hover {
  -webkit-box-shadow: 0px 0px 4px 4px rgba(172, 10, 8, 0.2);
  -moz-box-shadow: 0px 0px 4px 4px rgba(172, 10, 8, 0.2);
  box-shadow: 0px 0px 4px 4px rgba(172, 10, 8, 0.2);
  transition: all 0.5s ease;
}

.text1{
  color: #FFF;
  font-family: Muller;
  font-size: 100px;
  font-style: normal;
  font-weight: 800;
  line-height: 95%;
  width: 50%;
  margin: 70px 180px 20px 0px;
  position: relative;
  z-index: 2;
}
.text2{
  color: #FFF;
  font-family: Muller;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  width: 50%;
  margin: 0 180px 50px 0px;
  z-index: 2;
  position: relative;
}
.blockCar{
  position: absolute;
  top: 260px;
  left: 548px;
  z-index: 0;
  width: calc(100% - 550px);
}
.fair1{
  position: absolute;
  z-index: 0;
  width: 74%;
  transform: rotate(-180deg);
  margin: 15% 0 0 30%;
}
.fair2{
  position: absolute;
  z-index: 0;
  width: 60%;
  margin: 0 0 0 50px;
  transform: rotate(-140deg);
}
.car1{
  position: absolute;
  z-index: 0;
  width: 100%;
}
.rect1{
  border: 4px solid #AC0A08;
  box-shadow: 0px 0px 20px 0px #FF0300;
  width: 62%;
  height: 25%;
  position: absolute;
  top: -40px;
  left: -30px;
  z-index: -2;
}
.rect2{
  border: 4px solid #AC0A08;
  box-shadow: 0 0 20px 0 #FF0300;
  width: 50%;
  height: 270px;
  position: absolute;
  top: 500px;
  right: -40px;
  z-index: -2;
}
.block1{
  margin: 0 0 290px 0;
}
@media (max-width: 1350px){
  .text1{
    font-size: 80px;
  }
  .text2{
    font-size: 25px;
  }
  .rect2{
    top: 400px
  }
}
@media (max-width: 1200px){
  .block1{
    margin: 0 0 170px 0;
  }
  .rect2{
    width: 20%;
    top: 400px;
    right: -70px;
  }
}
@media (max-width: 1024px){
  .block1{
    margin: 0 0 100px 0;
  }
  .blockCar{
    position: initial;
    height: 480px;
  }
  .rect1{
    width: 90%;
    height: 18%;
    position: absolute;
    top: 80px;
    left: -70px;
  }
  .rect2{
    display: none;
  }
  .infoWrapper {
    display: flex;
    justify-content: space-between;
    margin: var(--px50) 5px;
  }

  .info {
    width: 100%;
  }

  .visual {
    width: 100%;
    margin: 20px 0;
  }
}
@media (max-width: 950px){
  .rect1{
    width: calc(100% + 60px);
    height: 18%;
    position: absolute;
    top: 80px;
    left: -70px;
  }
  .text2{
    width: 80%;
  }

}
@media (max-width: 800px) {
  .block1{
    margin: 0 0 0px 0;
  }
  .blockCar{
    height: 380px;
    margin-top: 100px;
  }
}
@media (max-width: 530px){
  .rect1{
    width: calc(100% + 60px);
    height: 420px;
    top: 80px;
    left: -70px;
  }
  .text1{
    font-size: 50px;
  }
  .text2{
    font-size: 20px;
  }
  .blockCar{
    height: 230px;
    margin-top: 80px;
  }
}
