.wrapper {
  border-radius: 3px;
  padding: var(--px30);
  margin: var(--px73) auto;
  background:linear-gradient(-45deg, transparent 28px, #2e2e2e 0);
  position: relative;
}
.wrapper:before {
  transition: all 0.5s ease;
  content: "";
  position: absolute;
  background: linear-gradient(-45deg, transparent 30px, #AC0A08 0);
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  z-index: -2;
  top: -4px;
  left: -4px;
}

.title {
  font-weight: 800;
  font-size: var(--px64);
  margin-bottom: var(--px14);
  text-align: center;
}

.description {
  font-weight: 400;
  font-size: var(--px30);
  margin-bottom: var(--px14);
}

.button {
  display: block;
  color: #fff;
  font-weight: 400;
  font-size: var(--px30);
  padding: var(--px14) var(--px24);
  background-color: #ac0a08;
  border-radius: 3px;
  text-decoration: none;
  font-family: "Muller";
  margin: 0 auto;
}

.button:hover {
  -webkit-box-shadow: 0px 0px 4px 4px rgba(172, 10, 8, 0.2);
  -moz-box-shadow: 0px 0px 4px 4px rgba(172, 10, 8, 0.2);
  box-shadow: 0px 0px 4px 4px rgba(172, 10, 8, 0.2);
}

.head{
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  transition: all .3s ease;
}
.head:hover{
  color: #ac0a08;
}
.head2{
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 140%;
   transition: all .3s ease;
   color: #ac0a08;
  display: flex;
}
.head2_2{
  margin: 5px 20px 0 20px;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-left: 8px solid #ac0a08;
  border-bottom: 5px solid transparent;
  display: block;
}
.headBlock{
  display: flex;
}