.wrapper {
  margin: var(--px48) auto;
  padding: var(--px48);
  text-align: left;
  position: relative;
  background: linear-gradient(-45deg, transparent 28px, #0d0d0d 0);
}
.wrapper:before {
  transition: all 0.5s ease;
  content: "";
  position: absolute;
  background: linear-gradient(-45deg, transparent 30px, #AC0A08 0);
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  z-index: -2;
  top: -4px;
  left: -4px;
}
.title {
  font-size: var(--px30);
  font-weight: 800;
  margin-bottom: var(--px20);
  display: flex;
}
.title2{
  color: #ac0a08;
  font-size: 22px;
  margin-right: 10px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-left: 16px solid #ac0a08;
  border-bottom: 10px solid transparent;
  display: block;
}
.text {
  font-size: var(--px26);
  margin-bottom: var(--px20);
}

.buttonLink {
  background-color: #ac0a08;
  font-size: var(--px30);
  transition: all 0.5s ease;
  padding: var(--px26) var(--px40);
  display: block;
  width: 30%;
  margin: 0 auto;
  border-radius: 10px;
}

.buttonLink:hover {
  -webkit-box-shadow: 0px 0px 4px 4px rgba(172, 10, 8, 0.2);
  -moz-box-shadow: 0px 0px 4px 4px rgba(172, 10, 8, 0.2);
  box-shadow: 0px 0px 4px 4px rgba(172, 10, 8, 0.2);
  transition: all 0.5s ease;
}
