.wrapper {
  display: flex;
  color: #ffffff;
  width: 100%;
  bottom: 0;
  left: 0;
  padding-bottom: var(--px30);
  padding-top: var(--px30);
  flex-direction: column;
  flex-wrap: wrap;
}

.contacts {
  width: 40%;
}

.warning {
  background-color: #ac0a08;
  width: 52%;
  padding: var(--px15);
  -webkit-box-shadow: 0px 0px 4px 4px rgba(172, 10, 8, 0.2);
  -moz-box-shadow: 0px 0px 4px 4px rgba(172, 10, 8, 0.2);
  box-shadow: 0px 0px 4px 4px rgba(172, 10, 8, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.warningTite {
  margin-bottom: var(--px30);
  font-weight: 800;
  font-size: var(--px30);
}

.warningText {
  font-size: var(--px24);
  margin-bottom: var(--px5);
}

.title {
  font-size: var(--px64);
  font-weight: 800;
  border: 4px solid #ac0a08;
  border-bottom: none;
  border-left: none;
  width: 50%;
  margin: 0 0 0 -7%;
  padding: 90px 0 20px 7%;
}
.title2{
  width: 46%;
  position: relative;
}
.title2:before{
  position: absolute;
  content: '';
  width: calc(120%);
  left: -4px;
  height: 100%;
  border: 4px solid #ac0a08;
  border-top: none;
  border-left: none;
  border-right: none;
}
.description {
  font-size: var(--px30);
  width: 45%;
  height: 120px;
}
.socials{
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.link {
  display: flex;
  align-items: center;
  margin-bottom: var(--px8);
  cursor: pointer;
  transition: all 0.5s ease;
}

.socialLink {
  display: flex;
  align-items: center;
  font-size: var(--px26);
  margin-left: var(--px4);
  transition: all 0.5s ease;
}

.circle {
  display: block;
  border-radius: 100%;
  content: "";
  margin-right: 0.6rem;
  width: var(--px12);
  height: var(--px12);
  background-color: #ac0a08;
  transition: all 0.5s ease;
  box-shadow: 0 0 20px 0 #FF0300;
}

.link:hover .circle {
  -webkit-box-shadow: 0px 0px 4px 4px rgba(172, 10, 8, 0.2);
  -moz-box-shadow: 0px 0px 4px 4px rgba(172, 10, 8, 0.2);
  box-shadow: 0px 0px 4px 4px rgba(172, 10, 8, 0.2);
  transition: all 0.5s ease;
}

.link:hover .socialLink {
  color: #ac0a08;
}

.block1{
  display: flex;
}
.block2{
  display: flex;
}
@media (max-width: 800px) {
.description{
  width: 100%;
  margin-top: 20px;
  height: 70px;
}
  .title{
    padding: 40px 0 20px 7%;
    font-size: 40px;
  }
  .block2{
    flex-direction: column;
  }
  .warning{
    width: calc(100% - 20px);
    margin-top: 20px;
  }
}
@media (max-width: 800px) {
  .title{
    padding: 35px 0 15px 7%;
    font-size: 30px;
  }
}
@media (max-width: 460px) {
  .title{
    padding: 35px 0 15px 7%;
    font-size: 25px;
  }
}