.wrapper {
  margin: var(--px60) 0;
}

.title {
  font-weight: 800;
  font-size: calc(var(--index) * 2);
  margin-bottom: var(--px14);
}

.description {
  font-weight: 400;
  font-size: var(--px30);
  width: 51%;
  margin-bottom: var(--px24);
}

.contacts {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.itemWrapper:before {
  transition: all 0.5s ease;
  content: "";
  position: absolute;
  background: linear-gradient(-45deg, transparent 30px, #AC0A08 0);
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  z-index: -2;
  top: -4px;
  left: -4px;
  opacity: 0;
}
.itemWrapper:hover:before {
  opacity: 1;
}
.itemWrapper {
  width: 42%;
  background: #2e2e2e;
  padding: var(--px30);
  /* margin: var(--px73) auto; */
  transition: all 0.5s ease;
  height: calc(var(--index) * 5);
  margin-bottom: var(--px24);
  position: relative;
  background:linear-gradient(-45deg, transparent 28px, #2e2e2e 0);
}
.circle {
  display: block;
  border-radius: 100%;
  margin-right: 0.5rem;
  width: var(--px24);
  height: var(--px24);
  background-color: #ac0a08;
  transition: all 0.5s ease;
}

.itemWrapper:hover .contactTitle2 {
  color: #ac0a08;
  border-left: 16px solid #ac0a08;
}
.contactTitle2{
  transition: all 0.5s ease;
  padding-right: 7px;
  font-size: 22px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-left: 16px solid #ffffff;
  border-bottom: 10px solid transparent;
  display: block;
}
.link {
  display: flex;
  /* align-items: center; */
}

.contactTitle {
  font-weight: 800;
  font-size: var(--px30);
  margin-bottom: var(--px14);
  cursor: pointer;
  transition: all 0.5s ease;
}

.contactDescription {
  font-size: var(--px24);
  font-weight: 400;
}

.head{
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  transition: all .3s ease;
}
.head:hover{
  color: #ac0a08;
}
.head2{
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  transition: all .3s ease;
  color: #ac0a08;
  display: flex;
}
.head2_2{
  margin: 5px 20px 0 20px;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-left: 8px solid #ac0a08;
  border-bottom: 5px solid transparent;
  display: block;
}
.headBlock{
  display: flex;
}
@media (max-width: 800px){
  .description{
    width: 100%;
  }
  .contactDescription{
    display: none;
  }
  .itemWrapper{
    height: 25px;
    width: 40%;
  }
  .itemWrapper:before{
    opacity: 1;
  }
  .itemWrapper:hover .contactTitle {
    color: #ac0a08;
  }
}
@media (max-width: 800px){
  .itemWrapper{
    width: 38%;
  }
}
@media (max-width: 420px){
  .itemWrapper{
    width: 37%;
    height: 18px;
  }
  .contactTitle{
    font-size: 16px;
  }
  .contactTitle2{
    font-size: 14px;
  }
}