.mainWrapper {
  margin: var(--px48) 0;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: var(--px64);
  font-weight: 800;
  margin: var(--px30) 0;
}

.arrow {
  margin-right: var(--px8);
}
.contactTitle2{
  font-size: 30px;
  margin-right: 10px;
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-left: 18px solid #ffffff;
  border-bottom: 12px solid transparent;
  display: block;
}
.contactTitle3{
  margin-left: 20px;
  color: #AC0A08;
  font-size: var(--20px);
  font-style: normal;
  font-weight: 400;
  line-height: 95%; /* 14.25px */
  text-decoration-line: underline;
}

.brandsWrapper {
  padding: var(--px20);
  margin-right: var(--px30);
  background:linear-gradient(-45deg, transparent 28px, #2e2e2e 0);
  position: relative;
  display: flex;
  flex-direction: column;
  height: fit-content;
}
.brandsWrapper:before {
  transition: all 0.5s ease;
  content: "";
  position: absolute;
  background: linear-gradient(-45deg, transparent 30px, #AC0A08 0);
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  z-index: -2;
  top: -4px;
  left: -4px;
}

.brandsTitle {
  font-size: var(--px40);
  font-weight: 800;
  display: flex;
}

.wrapper {
  /* max-height: calc(var(--index) * 3); */
  display: flex;
}
.wrapperBlock{
  display: block;
}

.itemsWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 20px 5px;
}

.head{
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  transition: all .3s ease;
}
.head:hover{
  color: #ac0a08;
}
.head2{
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  transition: all .3s ease;
  color: #ac0a08;
  display: flex;
}
.head2_2{
  margin: 5px 20px 0 20px;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-left: 8px solid #ac0a08;
  border-bottom: 5px solid transparent;
  display: block;
}
.headBlock{
  display: flex;
}
.brand{
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  cursor: pointer;
  transition: all .3s ease;
  display: flex;
  align-items: center;
}
.brand:hover{
  color: #ac0a08;
}
.brand:hover > .brandSpan{
  border-left: 16px solid #ac0a08;
}
.brandSpan{
  transition: all .3s ease;
  font-size: 20px;
  margin-right: 10px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-left: 16px solid #ffffff;
  border-bottom: 10px solid transparent;
  display: block;
}
.brands{
  margin-top: 10px;
  flex: 1; /* Даем блоку .brands возможность занимать оставшееся пространство */
}
.brandsWrapperFon{
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.98;
  background: rgba(12, 12, 12, 0.95);
  backdrop-filter: blur(2.5px);
  z-index: 2;
  top: 0;
  left: 0;
}
/*Пагинация*/

.pagination {
  width: 100%;
  display: flex;
  justify-content: right;
  margin-top: 4%;
}

.pagination button {
  border: none;
  background-color: transparent;
  color: #ffffff;
  font-size: var(--px18);
  font-weight: 400;
  cursor: pointer;
  margin: 0 5px;
  transition: color 0.3s ease;
}

.pagination button:hover {
  color: #ac0a08;
}

.pagination button.activePage {
  color: #ac0a08;
  font-weight: 600;
}
@media (max-width: 1099px) {
  .wrapper {
    flex-direction: column;
  }
}

@media (min-width: 1100px) {
  .wrapper {
    flex-direction: row;
  }
}
/*Конец пагинации*/
@media (max-width: 1100px){
  .brandsWrapper{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    backdrop-filter: blur(2.5px);
    max-height: calc(90vh - 140px);
    overflow: auto;
    scrollbar-width: thin;
  }
  .brandsWrapper:before {
    display: none;
  }
  .contactTitle2{
    border-top: 10px solid transparent;
    border-left: 16px solid #ffffff;
    border-bottom: 10px solid transparent;
  }
  .brandsTitle {
    margin-bottom: 20px;
  }
  .brandsWrapper::-webkit-scrollbar {
    display: none;
  }  
}

