/* ----------------Fonts---------------- */
@import url(./fonts/stylesheet.css); /* ------------Reset CSS--------------*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  border: none;
  background: transparent;
  cursor: pointer;
}

a {
  color: #fafafa;
  text-decoration: none;
}

body {
  height: 100;
  width: 90%;
  margin: 0 auto;
  background: linear-gradient(135deg, #222222, #313131, #0d0d0d) no-repeat;
  position: relative;
  color: #fff;
  font-family: "Muller";
  overflow-x: hidden;
}

.App {
  padding: var(--px30);
}

/* -------------VARS------------------ */
#root {
  --index: calc(1vh + 1vw);
  --px2: min(calc(var(--index) * 0.095), 3px);
  --px3: min(calc(var(--index) * 0.14), 3px);
  --px4: min(calc(var(--index) * 0.19), 4px);
  --px5: min(calc(var(--index) * 0.238), 5px);
  --px6: min(calc(var(--index) * 0.285), 6px);
  --px7: min(calc(var(--index) * 0.33), 7px);
  --px8: min(calc(var(--index) * 0.38), 8px);
  --px9: min(calc(var(--index) * 0.428), 9px);
  --px10: min(calc(var(--index) * 0.5), 10px);
  --px11: min(calc(var(--index) * 0.523), 11px);
  --px12: min(calc(var(--index) * 0.6), 12px);
  --px13: min(calc(var(--index) * 0.619), 13px);
  --px14: min(calc(var(--index) * 0.666), 14px);
  --px15: min(calc(var(--index) * 0.714), 15px);
  --px16: min(calc(var(--index) * 0.76), 16px);
  --px17: min(calc(var(--index) * 0.8), 17px);
  --px18: min(calc(var(--index) * 0.857), 18px);
  --px19: min(calc(var(--index) * 0.9), 19px);
  --px20: min(calc(var(--index) * 0.95), 20px);
  --px21: min(calc(var(--index) * 1), 21px);
  --px22: min(calc(var(--index) * 1.095), 22px);
  --px24: min(calc(var(--index) * 1.142), 24px);
  --px25: min(calc(var(--index) * 1.19), 25px);
  --px26: min(calc(var(--index) * 1.238), 26px);
  --px27: min(calc(var(--index) * 1.285), 27px);
  --px28: min(calc(var(--index) * 1.33), 28px);
  --px29: min(calc(var(--index) * 1.4), 29px);
  --px30: min(calc(var(--index) * 1.42), 30px);
  --px32: min(calc(var(--index) * 1.52), 32px);
  --px35: min(calc(var(--index) * 1.7), 35px);
  --px38: min(calc(var(--index) * 1.8), 38px);
  --px40: min(calc(var(--index) * 1.9), 40px);
  --px48: min(calc(var(--index) * 2.4), 48px);
  --px50: min(calc(var(--index) * 2.5), 50px);
  --px60: min(calc(var(--index) * 2.9), 60px);
  --px64: min(calc(var(--index) * 3.05), 64px);
  --px73: min(calc(var(--index) * 3.47), 73px);
  --px80: min(calc(var(--index) * 3.8), 80px);
  --px120: min(calc(var(--index) * 5.71), 120px);
  --px150: min(calc(var(--index) * 7.14), 150px);
  --px180: min(calc(var(--index) * 8.57), 180px);
  font-size: 62.5%; /* Assuming default of 16px, sets 1rem == 10px */
  min-height: 100vh;
}
