.wrapper {
  margin-bottom: var(--px60);
}

.brandsWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100%;
  justify-content: space-evenly;
}

.image {
  height: calc(var(--index) * 4.52);
  padding: var(--px30) var(--px24);
  cursor: pointer;
  transition: all .3s ease;
}
.image:hover{
  transform: scale(110%);
}

.title {
  font-size: var(--px30);
  font-weight: 800;
  transition: all 0.5s ease;
  display: flex;
}
.title2{
  color: #ac0a08;
  font-size: 22px;
  margin-right: 10px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-left: 16px solid #ac0a08;
  border-bottom: 10px solid transparent;
  display: block;
}
.title:hover {
  color: #ac0a08;
}

.description {
  font-size: var(--px26);
  font-weight: 400;
  display: block;
  width: 47%;
  margin-bottom: var(--px30);
}

.labelWrapepr {
  display: flex;
  margin-bottom: var(--px30);
}

.circle {
  display: block;
  border-radius: 100%;
  content: "";
  margin-right: 0.5rem;
  width: var(--px26);
  height: var(--px26);
  background-color: #ac0a08;
  transition: all 0.5s ease;
}

.labelWrapepr:hover .circle {
  -webkit-box-shadow: 0px 0px 4px 4px rgba(172, 10, 8, 0.2);
  -moz-box-shadow: 0px 0px 4px 4px rgba(172, 10, 8, 0.2);
  box-shadow: 0px 0px 4px 4px rgba(172, 10, 8, 0.2);
  transition: all 0.5s ease;
}
@media (max-width: 1024px){
  .description{
    width: 100%;
  }
}
