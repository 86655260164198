.Cases {
    margin-bottom: 0.5rem;
}

.heading {
    font-size: 4rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 2rem;
    color: white;
    @media (max-width: 860px) {
        font-size: 2rem;
        margin-bottom: 1rem;
    }
}

.slide {
    position: relative;
    background-repeat: no-repeat;
    border: 2px solid #AC0A08;
    border-radius: 2rem;
    @media (max-width: 860px) {
        border-radius: 2rem;
    }
}
.slideImage{
    height: 100%;
}
.video {
    width: 100%;
    height: 100%;
    object-fit: cover; 
    border-radius: 2rem; 
    @media (max-width: 768px) {
        border-radius: 2rem;
    }
}
.overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease;
}
.iconPlayer {
    width: 50px;
    height: 50px;
    fill: white;
}
.slide:hover .overlay {
    opacity: 1;
}


.icon {
    width: 1rem;
    height: 2rem;
    color: #2e2e2e;
    color: #AC0A08;
    @media (max-width: 768px) {
        width: 1rem;
        height: 2rem;
        color: #AC0A08;
    }
}

.roundedBtn {
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #2e2e2e;
    border: 2px solid #AC0A08;
    border-radius: 50%;
    cursor: pointer;
    transition: all .3s ease;

    @media (max-width: 840px) {
        width: 3rem;
        height: 3rem;
        border-color: #AC0A08;
    }
}

.roundedBtn:first-of-type {
    margin-right: 3rem;
}

.roundedBtn:hover {
    transform: scale(1.03);
}

.navigation {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.swiperWrap {
    height: 40rem;
    @media (max-width: 840px) {
        margin-bottom: 2rem;
        height: 30rem;
    }
}


.iphone {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: calc(65rem * .461);
    border-radius: 1rem;
    background-repeat: no-repeat;
}



/* Стили для минималистичной полоски внизу видео */

