.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;
  margin-bottom: 50px;
}
.rightPart {
  display: flex;
}
.currencyIcons {
  margin-right: var(--px20);
}
.currencyIcon {
  font-size: var(--px24);
  margin-left: var(--px20);
  margin-top: -2%;
  color: #FFFFFF;
}
.currencyIcon:hover {
  color: #ac0a08;
  transition: all 1000ms cubic-bezier(0.075, 1, 0.25, 1);
}
.activeCurrency {
  color: #ac0a08;
}
@media (max-width: 1000px){
  .currencyIcons {
    display: contents;
    
  }
  .currencyIcon {
    font-size: var(--px24);
    margin-left: var(--px6);
    margin-top: -0.7%;

  }
}
.navLink {
  font-size: var(--px24);
  margin-left: var(--px60);
  transition: all 1000ms cubic-bezier(0.075, 1, 0.25, 1);
}

.navLink:first-child {
  margin-left: 0;
}

.navLink:hover {
  color: #ac0a08;
  transition: all 1000ms cubic-bezier(0.075, 1, 0.25, 1);
}
.navLink2 {
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  transition: all 1000ms cubic-bezier(0.075, 1, 0.25, 1);
  margin: 10px;
  margin-left: 35px;
}
.navLink2:hover {
  color: #ac0a08;
  transition: all 1000ms cubic-bezier(0.075, 1, 0.25, 1);
}
.menuText{
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 10px;
  display: flex;
}
.menuBlock{
  width: 35px;
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: .8;
  transition: all .3s;
  cursor: pointer;
}
.menuBlock:hover{
  opacity: 1;
}
.menuBlock2{
  width: 35px;
  height: 25px;
  opacity: .8;
  transition: all .3s;
  cursor: pointer;
  z-index: 12;
  position: fixed;
  left: 40px;
  top: 65px;
}
.menuBlock2:hover{
  opacity: 1;
}
.menuBlockItem{
  height: 3px;
  width: 100%;
  background: #FFFFFF;
}
.menuBlockItem2{
  height: 3px;
  width: 100%;
  background: #FFFFFF;
  transform: rotate(45deg);
}
.menuBlockItem3{
  height: 3px;
  width: 100%;
  background: #FFFFFF;
  transform: rotate(-45deg);
  margin: -3px 0px 0px 0px;
}
.menuFon{
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(12, 12, 12, 0.98);
  opacity: 0.9;
  backdrop-filter: blur(10px);
  left: 0;
  top: 0;
}
.menu{
  position: fixed;
  z-index: 12;
  display: flex;
  flex-direction: column;
  background: rgba(12, 12, 12, 0.98);
  backdrop-filter: blur(10px);
  width: calc(100% - 120px);
  max-width: 260px;
  padding: 40px;
  border: 4px solid #AC0A08;
  box-shadow: 0px 0px 20px 0px #FF0300;
}
.contactTitle2{
  margin-right: 10px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-left: 16px solid #ffffff;
  border-bottom: 10px solid transparent;
  display: block;
}