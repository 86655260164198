html{
    scroll-behavior: smooth;
  }
.swiper-wrapper{
    @media (max-width: 860px) {
      margin-left: -62%;
    }
}
body {
  font-family: 'OpenSans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
