.commom {
  display: flex;
  justify-content: space-between;
  width:100%;
  max-width: 100%;
}
.imageWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 65%;
  height: 650px;
}
.image{
  width: 100%;
  max-width: 100%; 
  max-height: 100%; 
  clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 40px),calc(100% - 40px) 100%, 0% 100%);
}
.image img{
  height: 100%;
  width: auto;
  flex-shrink: 0;
}
.imageBlock {
  width: 95%;
  overflow: hidden;
  display: flex;
  margin: auto;
  max-height: 90%;
  margin-top: 1%;
  margin-bottom: 1%;
  align-items: center;
  flex-direction: column;
}

.infoCommon {
  position: relative;
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.model {
  font-size: var(--px48);
  margin-bottom: var(--px26);
}

.infoTitle {
  font-size: var(--px30);
  font-weight: 800;
  margin-bottom: var(--px16);
  display: flex;
}

.shortInfoItem {
  font-size: var(--px25);
  margin-bottom: var(--px12);
}

.cost {
  font-size: var(--px30);
  border: 4px solid #ac0a08;
  text-align: center;
  padding: var(--px14);
  width: calc(100% - 36px);
}
.title2{
  padding-right: 7px;
  font-size: 22px;
  width: 0;
  height: 0;
  border-top: 11px solid transparent;
  border-left: 20px solid #ac0a08;
  border-bottom: 11px solid transparent;
  display: block;
}

.cost:hover {
  -webkit-box-shadow: 0px 0px 4px 4px rgba(172, 10, 8, 0.2);
  -moz-box-shadow: 0px 0px 4px 4px rgba(172, 10, 8, 0.2);
  box-shadow: 0px 0px 4px 4px rgba(172, 10, 8, 0.2);
  transition: all 0.5s ease;
}
.sharedInfo {
  display: flex;
}

.sharedInfoTitle {
  font-weight: 700;
}

.sharedInfoTitle,
.sharedInfoText {
  font-size: var(--px25);
}
.shared {
  margin: var(--px40) 0;
  padding: var(--px30);
  position: relative;
  background:linear-gradient(-45deg, transparent 28px, #2e2e2e 0);
}
.shared:before {
  transition: all 0.5s ease;
  content: "";
  position: absolute;
  background: linear-gradient(-45deg, transparent 30px, #AC0A08 0);
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  z-index: -2;
  top: -4px;
  left: -4px;
}

.head{
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  transition: all .3s ease;
  display: flex;
}
.head:hover{
  color: #ac0a08;
}
.head2{
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  transition: all .3s ease;
  color: #ac0a08;
  display: flex;
}
.head2_2{
  margin: 5px 20px 0 20px;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-left: 8px solid #ac0a08;
  border-bottom: 5px solid transparent;
  display: block;
}
.nextImageB{
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-left: 10px solid #ac0a08;
  border-bottom: 7px solid transparent;
  display: block;
  transition: all .3s ease;
}
.headBlock{
  display: flex;
  margin-bottom: 30px;
}
.nextBlock{
  left: calc(50% - 40px);
  bottom: 20px;
  display: flex;
  justify-content: center;
}
.nextImage{
  color: #ac0a08;
  height: 30px;
  width: 30px;
  border: 1px solid #ac0a08;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  cursor: pointer;
  margin-left: 10px;
  transition: all .3s ease;
}
.nextImage:hover{
  background: #ac0a08;
  color: #FFFFFF;
}
.nextImage:hover > .nextImageB{
  border-left: 10px solid #ffffff;
}
.nextImage2:hover > .nextImageB{
  border-left: 10px solid #ffffff;
}
.nextImage2 {
  color: #ac0a08;
  height: 30px;
  width: 30px;
  border: 1px solid #ac0a08;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  cursor: pointer;
  transform: rotate(180deg);
  margin-right: 10px;
  transition: all .3s ease;
}
.nextImage2:hover{
  background: #ac0a08;
  color: #FFFFFF;
}
@media (max-width: 1100px){
  .commom {
    flex-direction: column-reverse;
  }
  .imageWrapper {
    width: 100%;
    height: 500px;
  }
  .imageBlock{
    width: 95%;
    margin-top: 10px;
  }
  .infoCommon{
    width: calc(100% - 40px);
    padding: 20px;
    position: relative;
    background:linear-gradient(-135deg, transparent 28px, #2e2e2e 0);
  }
  .infoCommon:before {
    transition: all 0.5s ease;
    content: "";
    position: absolute;
    background: linear-gradient(-135deg, transparent 30px, #AC0A08 0);
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    z-index: -2;
    top: -4px;
    left: -4px;
  }
}

@media (max-width: 900px){
  .imageWrapper {
    width: 100%;
    height: 380px;
  }
  .imageBlock{
    width: 95%;
    margin-top: 10px;
  }
}
@media (max-width: 800px){
  .imageWrapper {
    width: 100%;
    height: 330px;
  }
  .imageBlock{
    width: 95%;
    margin-top: 10px;
  }
}
@media (max-width: 700px){
  .imageWrapper {
    width: 100%;
    height: 215px;
  }
  .imageBlock{
    width: 95%;
    margin-top: 10px;
  }
}