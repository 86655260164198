._{
    background: #AC0A08;
    width: 315px;
    height: 74px;
    color: #FAFAFA;
    font-family: Muller;
    font-size: 30.414px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    border: none;
    cursor: pointer;
    background:linear-gradient(-45deg, transparent 15px, #AC0A08 0);
}
@media (max-width: 1024px){
    ._{
        font-size: 26px;
        width: 300px;
        height: 66px;
    }
}
@media (max-width: 550px){
    ._{
        font-size: 22px;
        width: 300px;
        height: 66px;
    }
}
@media (max-width: 440px){
    ._{
        font-size: 19px;
        width: 280px;
        height: 60px;
    }
}
@media (max-width: 400px){
    ._{
        font-size: 17px;
        width: 280px;
        height: 60px;
    }
}