.wrapper {
  width: 30%;
  background: linear-gradient(-45deg, transparent 30px, #2e2e2e 0);
  transition: all 0.5s ease;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 50%;
}
.wrapper:hover{
  background: linear-gradient(-45deg, transparent 30px, #ac0a08 0);
}
.image {
  width: 100%;
  max-width: 100%; 
  min-height: 100%; 
}
.image img{
  height: 100%;
  width: auto;
  flex-shrink: 0;
}
.imageBlock{
  display: flex;
  margin: auto;
  margin-top: 1%;
  margin-bottom: 1%;
  width: 98%; 
  max-height: 45%;
  min-height: 45%;
  align-items: center;
  overflow: hidden;
}
.model {
  font-size: var(--px28);
  font-weight: 700;
  margin-bottom: var(--px8);
  margin-left: 3%;
  display: flex;
}

.paramTitle {
  font-size: var(--px20);
  margin-bottom: var(--px8);
}

.costParam {
  font-size: var(--px24);
  bottom: 2%;
  display: flex;
  flex-direction: column;
}

.parameters {
  padding: var(--px8);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.params {
  display: flex;
  flex-direction: column;
}
.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 40%;
  max-height: 100%;
}

@media (min-width: 420px) and (max-width: 770px){
  .wrapper {
    width: 300px;
    height: 310px;
  }
  .imageBlock{
    max-height: 50%;
    min-height: 50%;
  }
}
@media (max-width: 419px){
  .wrapper {
    height: 240px;
    width: 250px;
  }
  .imageBlock{
    max-height: 50%;
    min-height: 50%;
  }
}
@media (min-width: 771px) and (max-width: 900px){
  .wrapper {
    width: 300px;
    height: 330px;
  }
}
@media (min-width: 901px) and (max-width: 1099px){
  .wrapper {
    width: 320px;
    height: 350px;
  }
}
@media (min-width: 1100px) and (max-width: 1199px){
  .wrapper {
    width: 250px;
    height: 400px;
  }
}
@media (min-width: 1200px) and (max-width: 1300px){
  .wrapper {
    width: 320px;
    height: 400px;
  }
}
@media (min-width: 1301px) and (max-width: 1400px){
  .wrapper {
    width: 340px;
    height: 420px;
  }
}
@media (min-width: 1301px) and (max-width: 1400px){
  .wrapper {
    width: 350px;
    height: 450px;
  }
}